/*
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-07-20 17:27:12
 * @LastEditTime: 2022-08-10 10:17:24
 */
import axios from '@/utils/request'
let Api = {
	enumKeyList: "/common/enums/getByEnumKey",
}
// 枚举列表
export function getEnumKeyList(params) {
	return axios({
		method: 'get',
		url: Api.enumKeyList,
		params: params
	})
}
