<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-06-30 10:44:52
-->
<template>
	<div class="baseActivityVerify">
		<div class="baseActivityVerify-content">
			<el-tabs v-model="currentTab" @tab-click="changeTab">
				<el-tab-pane label="未审核" name="1"></el-tab-pane>
				<el-tab-pane label="已审核" name="2"></el-tab-pane>
			</el-tabs>
			<div v-if="currentTab == 2" class="activityVerify-search">
				<div class="common-row">
					<div class="common-row-item">
						<span class="common-row-label">审核时间</span>
						<el-date-picker value-format="yyyy-MM-dd" class="common-row-ipt" v-model="searchForm.time"
							type="daterange" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间">
						</el-date-picker>
					</div>
					<div class="common-row-item">
						<span class="common-row-label">策划提交方</span>
						<el-select class="common-row-ipt" v-model="searchForm.organizerId" placeholder="请选择">
							<el-option v-for="item in originList" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</div>
					<div class="common-row-item">
						<span class="common-row-label">活动ID</span>
						<el-input class="common-row-ipt" placeholder="请输入活动ID" v-model="searchForm.activityId" />
					</div>
				</div>
				<div class="common-row">
					<div class="common-row-item">
						<span class="common-row-label">活动名称</span>
						<el-input class="common-row-ipt" placeholder="请输入活动名称" v-model="searchForm.name" />
					</div>
					<div class="common-row-item">
						<span class="common-row-label">审核状态</span>
						<el-select class="common-row-ipt" v-model="searchForm.auditStatus" placeholder="请选择">
							<el-option v-for="item in statusList" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</div>
					<div class="common-row-item flex-between">
						<div class="flex-item">
							<el-button @click="search" type="primary">查询</el-button>
							<el-button @click="reset">重置</el-button>
						</div>
						<span @click="downLoadFile" class="common-row-btn">导出数据</span>
					</div>
				</div>
			</div>
			<div class="activityVerify-table">
				<el-table :data="tableData" border>
					<el-table-column width="160px" prop="id" label="活动ID">
					</el-table-column>
					<el-table-column prop="activityName" label="活动名称">
					</el-table-column>
					<el-table-column v-if="currentTab == 1" prop="createTime" label="活动时间">
						<template slot-scope="scope" v-if="scope.row.starTime&&scope.row.endTime">
							<div class="table-action-date">
								<span>{{`${dateFormat(scope.row.starTime)} 至 ${dateFormat(scope.row.endTime)}`}}</span>
							</div>
						</template>
					</el-table-column>
					<el-table-column v-if="currentTab == 2" prop="activityStatus" label="是否过审">
						<template slot-scope="scope">
							<span>{{scope.row.activityStatus > 1?'已过审':'未过审'}}</span>
						</template>
					</el-table-column>
					<el-table-column v-if="currentTab == 2" prop="auditOpinion" label="未过原因">
						<template slot-scope="scope">
							<span>{{scope.row.auditOpinion}}</span>
						</template>
					</el-table-column>
					<el-table-column v-if="currentTab == 2" prop="deduction" label="扣分原因">
						<template slot-scope="scope" v-if="scope.row.deduction&&scope.row.deduction.length > 0">
							<span v-for="(item, index) in scope.row.deduction" :key="index">{{item}}</span>
						</template>
					</el-table-column>
					<el-table-column v-if="currentTab == 2" prop="twoAudit" label="是否二审">
						<template slot-scope="scope">
							<span>{{scope.row.twoAudit?"是":"否"}}</span>
						</template>
					</el-table-column>
					<el-table-column v-if="currentTab == 2" prop="twoAuditReason" label="二审原因">
						<template slot-scope="scope">
							<span>{{scope.row.twoAuditReason}}</span>
						</template>
					</el-table-column>
					<el-table-column v-if="currentTab == 2" prop="reviewer" label="审核人">
					</el-table-column>
					<el-table-column prop="activityType" label="活动分类">
						<template slot-scope="scope">
							<span>{{scope.row.activityType == 3?"B类":"A类"}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="comBaseName" label="共建基地">
					</el-table-column>
					<el-table-column prop="organizer" label="组织名称">
					</el-table-column>
					<!-- <el-table-column v-if="currentTab == 2" prop="activityStatus" label="状态">
						<template slot-scope="scope">
							<div
								:class="{'table-action-green': scope.row.activityStatus == 2, 'table-action-red': scope.row.activityStatus == 0}">
								{{scope.row.activityStatus == 0?"未通过":scope.row.activityStatus == 2?"已通过":''}}
							</div>
						</template>
					</el-table-column> -->
					<el-table-column width="120px" prop="createBy" label="提交人">
					</el-table-column>
					<el-table-column width="160px" prop="createTime" label="提交时间">
					</el-table-column>
					<el-table-column width="160px" prop="action" label="操作">
						<template slot-scope="scope">
							<div class="table-action">
								<!-- <span @click="actionFn('verify', scope)">{{currentTab == 1?'审核':'二次审核'}}</span> -->
								<span v-if="currentTab == 1" @click="actionFn('verify', scope)">审核</span>
								<span v-if="currentTab == 2" @click="actionFn('verifyAgain', scope)">二次审核</span>
							</div>
						</template>
					</el-table-column>
				</el-table>
				<div class="common-pageNation">
					<el-pagination background @current-change="currentChange" :current-page.sync="pageIndex"
						:page-size="pageSize" layout="total, prev, pager, next" :total="totalCount">
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getBaseActivityVerifyList,
		downloadBaseActivity
	} from "@/api/base";
	import {
		getEnumKeyList
	} from "@/api/index.js";
	import {
		allOrigin,
	} from "@/api/system";
	export default {
		data() {
			return {
				tableData: [],
				totalCount: 0,
				pageIndex: 1,
				pageSize: 10,
				currentTab: '1',
				statusList: [{
						label: "未通过",
						value: 0
					},
					{
						label: "已过审",
						value: 1
					},
				],
				originList: [],
				deductionList: [],
				searchForm: {}
			};
		},
		async mounted() {
			await this.getDeductionList()
			this.getAllOriginList()
			this.baseActivityVerifyList(1)
		},
		methods: {
			getAllOriginList() {
				allOrigin().then((res) => {
					if (res.code == 0) {
						this.originList = res.data.map(item => {
							return {
								label: item.name,
								value: item.id
							}
						})
					}
				})
			},
			getDeductionList() {
				return new Promise((resove, reject) => {
					getEnumKeyList({
						enumKey: "AUDIT_REASONS"
					}).then((res) => {
						if (res.code == 0) {
							this.deductionList = res.data
							resove()
						} else {
							reject()
						}
					})
				})
			},
			search() {
				this.pageIndex = 1
				this.baseActivityVerifyList(1)
			},
			reset() {
				this.searchForm = {}
				this.pageIndex = 1
				this.baseActivityVerifyList(1)
			},
			downLoadFile() {
				this.baseActivityVerifyList(2);
			},
			changeTab(e) {
				this.currentTab = e.name
				this.pageIndex = 1;
				this.baseActivityVerifyList(1);
			},
			dateFormat(intDate) {
				if (intDate) {
					return intDate.replace(" 00:00:00", "")
				}
			},
			// 共建基地活动审核列表
			baseActivityVerifyList(type) {
				let params = {
					pageIndex: this.pageIndex,
					pageSize: this.pageSize,
					status: this.currentTab,
					activityType: 2,
				}
				if (this.currentTab == 2) {
					if (this.searchForm.time) {
						this.$set(params, "auditTimeStar", this.searchForm.time[0])
						this.$set(params, "auditTimeEnd", this.searchForm.time[1])
					}
					this.$set(params, "organizerId", this.searchForm.organizerId)
					this.$set(params, "activityId", this.searchForm.activityId)
					this.$set(params, "activityName", this.searchForm.name)
					this.$set(params, "auditStatus", this.searchForm.auditStatus)
				}
				if (type == 1) {
					getBaseActivityVerifyList(params).then((res) => {
						if (res.code == 0) {
							this.tableData = res.data
							this.totalCount = res.page.totalCount * 1
						}
					})
				} else {
					downloadBaseActivity(params).then((res) => {
						console.log("downloadBaseActivity", res)
						const blob = new Blob([res], {
							type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
						})
						// const blob = new Blob([res], {type: "application/vnd.ms-excel;charset=utf-8"})
						if ('download' in document.createElement('a')) {
							// 非IE下载
							const elink = document.createElement('a')
							elink.download = "共建基地活动审核数据表.xlsx"
							elink.style.display = 'none'
							elink.href = URL.createObjectURL(blob)
							document.body.appendChild(elink)
							elink.click()
							URL.revokeObjectURL(elink.href) // 释放URL 对象
							document.body.removeChild(elink)
						} else {
							// IE10+下载
							navigator.msSaveBlob(blob, "共建基地活动审核数据表.xlsx")
						}
					})
				}
			},
			currentChange(val) {
				this.pageIndex = val;
				this.tableData = [];
				this.baseActivityVerifyList(1);
			},
			actionFn(type, scope) {
				switch (type) {
					case "verify":
						this.$router.push({
							path: `/baseActivityVerify/verify?activityId=${scope.row.id}`
						})
						break;
					case "verifyAgain":
						this.$router.push({
							path: `/baseActivityVerify/verify?activityId=${scope.row.id}&&verifyAgain=Yes`
						})
						break;
				}
			},
		}
	};
</script>

<style lang="scss" scoped>
	/deep/.el-input__inner {
		height: 36px;
		border-radius: 0px 2px 2px 0px;
		border: 0;
	}

	/deep/.el-button {
		height: 36px;
	}

	.flex-item {
		display: flex;
		align-items: center;
	}

	.flex-between {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.baseActivityVerify {
		.baseActivityVerify-content {
			padding: 0 20px 20px 20px;

			.activityVerify-table {
				margin-top: 20px;
			}

			.activityVerify-search {
				.common-row {
					margin: 16px 0;

					.common-row-btn {
						width: 70px;
						height: 36px;
						font-size: 14px;
						font-family: PingFang SC-Regular, PingFang SC;
						font-weight: 400;
						color: #606266;
						border-radius: 4px;
						border: 1px solid #DCDFE6;
						cursor: pointer;
						display: flex;
						align-items: center;
						justify-content: center;
					}

					.common-row-item {
						margin-right: 20px;

						.common-row-ipt {
							flex: 1;
							height: 36px;
							line-height: 36px;
							border: 1px solid #EDF1F7;
							border-left: 0;
						}
					}
				}
			}

			.table-action-green {
				color: $theme_color;
			}

			.table-action-red {
				color: red;
			}

			.table-action-date {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				span {
					font-size: 14px;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 20px;
					color: #303133;
				}
			}
		}
	}
</style>
